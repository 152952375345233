<template>
  <div class="x-wrap" style="display: grid; gap: 0.5rem">
    <div
      v-for="(pm, index) in paymentMethods"
      :key="index"
      class="x-card"
      style="display: grid; gap: 0.5rem"
      :data-clickable="useCase === 'flow'"
      @click="$emit('select', pm.id)"
    >
      <div class="flex flex-align-start">
        <icon
          v-if="getPaymentIcon(pm.brand)"
          :icon="getPaymentIcon(pm.brand)"
        />
        <p v-else>{{ pm.brand }}</p>
        <icon
          v-if="useCase === 'settings'"
          id="trash"
          icon="Trash"
          style="margin-left: auto"
          button
          hover="primary"
          @click="showDeletePaymentModal(pm.id)"
        />
      </div>
      <p v-if="pm.lastFour" class="x-card-numbers">
        **** **** **** {{ pm.lastFour }}
      </p>
      <p v-if="pm.name" class="x-username ellipsis">{{ pm.name }}</p>
    </div>
    <modal
      ref="delete_payment_method"
      x-class="container-xs"
      x-style="width: 100%;"
      :bg="false"
      overlay
    >
      <grid
        style="
          background: white;
          padding: 1rem;
          border-radius: var(--border-radius-l);
        "
        gap="1.5"
      >
        <x-close-button
          style="margin-left: auto"
          @click="$refs.delete_payment_method.hide()"
        />
        <p style="font-weight: bold; text-align: center" class="fs-16">
          {{ $t('payment-methods.are-you-sure') }}
        </p>
        <p style="line-height: 2.2; text-align: center" class="fs-15">
          {{ $t('payment-methods.delete-msg') }}
        </p>
        <x-button
          id="delete-payment"
          style="margin-top: 2rem"
          @click="deletePaymentMethod()"
        >
          {{ $t('payment-methods.delete') }}</x-button
        >
      </grid>
    </modal>
  </div>
</template>

<script>
export default {
  name: 'SavedPaymentsSection',
  props: { useCase: String, paymentMethods: Array },
  data() {
    return {
      deletePaymentId: undefined,
    }
  },
  methods: {
    showDeletePaymentModal(id) {
      this.deletePaymentId = id
      this.$refs.delete_payment_method.show()
    },
    deletePaymentMethod() {
      this.$store.dispatch(
        'UserModule/deletePaymentMethod',
        this.deletePaymentId,
      )
      this.$refs.delete_payment_method.hide()
    },
    getPaymentIcon: brand =>
      ['Visa', 'PayPal', 'ApplePay', 'American', 'MasterCard'].find(
        validBrand =>
          brand && brand.toLowerCase().includes(validBrand.toLowerCase()),
      ),
  },
}
</script>

<style scoped lang="scss">
.x-wrap {
  color: #333;
}
.x-card {
  box-shadow: var(--shadow-app);
  padding: 1.5rem;
  border-radius: var(--border-radius-m);
  background: white;
}
.x-card[data-clickable] {
  background: white;
  border-radius: 8px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
  cursor: pointer;
  transition: box-shadow 0.2s ease-in-out;
  &:hover {
    box-shadow: var(--shadow-app-2);
    background: #fcfcfc;
  }
}
.x-card-numbers {
  font-family: DDin;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.5rem;
}
.x-username {
  font-family: DDin;
  font-size: 1rem;
}
</style>
