<template>
  <div style="display: grid; gap: 0.5rem" class="container-xs">
    <AppHeader back-to="/profile" />
    <p class="app-subtitle" style="margin-top: 2rem">PAYMENT</p>
    <PaymentMethodsSection
      use-case="settings"
      :payment-methods="paymentMethods"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AppHeader from '../../components-rf/AppHeader.vue'
import PaymentMethodsSection from '../../components/common/PaymentMethodsSection.vue'
import { appAnalytics } from '../../services/analytics/analytics'

export default {
  name: 'ProfilePayments',
  components: {
    PaymentMethodsSection,
    AppHeader,
  },
  mounted() {
    appAnalytics.page({ title: 'settings_screen_view' })
  },
  computed: mapState('UserModule', ['paymentMethods']),
}
</script>

<style scoped lang="scss">
.x-edit-profile-button {
  font-family: DDin;
  text-decoration: underline;
  display: grid;
  align-items: center;
  align-self: flex-end;
  gap: 0.5rem;
  grid-template-columns: auto 1fr;
  margin-top: 1.5rem;
}
.settings-additional-buttons {
  display: flex;
  justify-content: flex-end;
  & > * + * {
    margin-left: 1rem;
  }
}
</style>
