<template>
  <div style="display: grid; gap: 0.5rem">
    <p
      v-if="paymentMethods.length"
      class="app-subtitle"
      style="margin-top: 0.5rem"
    >
      {{
        useCase === 'settings'
          ? $t('settings.saved-payment-methods')
          : $t('settings.payment-method')
      }}
    </p>
    <saved-payments-section
      v-if="paymentMethods.length"
      id="saved-payments"
      :payment-methods="paymentMethods"
      :use-case="useCase"
      @select="id => $emit('select', id)"
    />
    <p class="app-subtitle" style="margin-top: 2rem">
      {{ $t('settings.add-new-payment-method') }}
    </p>
    <settings-cards id="settings-cards" :data="newPaymentCards" />
  </div>
</template>

<script>
import SettingsCards from '@/components/common/SettingsCards.vue'
import SavedPaymentsSection from '@/components/settings/SavedPaymentsSection.vue'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'PaymentMethodsSection',
  props: { useCase: String },
  created() {
    if (this.isLoggedIn) this.getPaymentMethods
  },
  components: {
    SavedPaymentsSection,
    SettingsCards,
  },
  data() {
    return {
      newPaymentCards: [
        {
          icons: ['Visa', 'MasterCard', 'American'],
          id: 'add-new-card',
          click: () => this.$router.push('/profile/payments/add-card'),
        },
      ],
    }
  },
  methods: mapActions('UserModule', ['getPaymentMethods']),
  computed: {
    ...mapState('AuthModule', ['isLoggedIn']),
    ...mapState('UserModule', ['paymentMethods']),
  },
}
</script>

<style scoped lang="scss">
.x-section-title {
  font-size: 1.125rem;
  font-family: DDin;
}
#paypal-button-container {
  position: relative;
  z-index: 0;
  background: #fcfcfc !important;
}
</style>
